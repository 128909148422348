export const novaPhotos = [
    {
      src: "/images/horses/nova1.jpeg",
      width: 9.722,
      height: 7.569
    },
    {
      src: "/images/horses/nova2.jpeg",
      width: 9,
      height: 7.599
    },
    {
      src: "/images/horses/Nova7.jpeg",
      width: 9,
      height: 8.143
    },
    {
      src: "/images/horses/nova5.jpeg",
      width: 14.583,
      height: 9.736
    },
    {
    src: "/images/horses/nova4.jpeg",
    width: 10,
    height: 9.398
    },
    {
      src: "/images/horses/Nova6.jpeg",
      width: 9,
      height: 6.22
    }
    
  ];
  
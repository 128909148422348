export const maestroPhotos = [
    {
      src: "/images/horses/maestro1.jpeg",
      width: 4,
      height: 3
    },
    {
    src: "/images/horses/maestro2.jpeg",
    width: 4,
    height: 3
    },
    {
      src: "/images/horses/maestro3.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/images/horses/maestro6.jpeg",
      width: 2,
      height: 4
    }
  ];
  
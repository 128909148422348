export const missMePhotos = [
    {
      src: "/images/horses/MissMe1.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/images/horses/MissMe2.jpeg",
      width: 3,
      height: 2
    },
    {
      src: "/images/horses/missme3.jpeg",
      width: 3,
      height: 4
    },
    {
      src: "/images/horses/missme5.jpg",
      width: 3,
      height: 4
    }
  ];
  
export const kimaPhotos = [
    {
      src: "/images/horses/kima2.jpeg",
      width: 4,
      height: 3
    },
    {
    src: "/images/horses/kima3.jpeg",
    width: 4,
    height: 3
    },
    {
      src: "/images/horses/kima5.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/images/horses/kima4.jpeg",
      width: 4,
      height: 3
    }
  ];
  
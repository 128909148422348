export const breePhotos = [
    {
      src: "/images/horses/bree1.jpeg",
      width: 3,
      height: 2
    },
    {
      src: "/images/horses/bree4.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/images/horses/bree2.jpeg",
      width: 3,
      height: 4
      },
      {
        src: "/images/horses/bree6.jpeg",
        width: 3,
        height: 4
      }
  ];
  
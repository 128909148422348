export const valkyriePhotos = [
    {
      src: "/images/horses/val1.jpeg",
      width: 4,
      height: 3
    },
    {
    src: "/images/horses/val2.jpeg",
    width: 4,
    height: 3
    },
    {
      src: "/images/horses/val3.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/images/horses/val4.jpeg",
      width: 4,
      height: 3
    }
  ];
  
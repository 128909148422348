export const effiePhotos = [
    {
      src: "/images/horses/effie1.jpeg",
      width: 4,
      height: 3
    },
    {
    src: "/images/horses/effie2.jpeg",
    width: 4,
    height: 3
    },
    {
      src: "/images/horses/effie3.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/images/horses/effie4.jpeg",
      width: 4,
      height: 3
    }
  ];
  
export const maxPhotos = [
    {
      src: "/images/horses/max1.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/images/horses/max2.jpeg",
      width: 4,
      height: 3
    },
    {
      src: "/images/horses/max3.jpeg",
      width: 4,
      height: 3
    },
    {
    src: "/images/horses/max4.jpeg",
    width: 3,
    height: 4
    }
  ];
  